import { AuthMode } from '@utils/Constants';
import * as qs from 'qs';
import { apiRoute, routeLayout } from 'src/paths';
import axiosInstanceWithInterceptors from 'src/services/InterceptorsForAxiosRequests';

export const localStorageKeys = {
  AUTH_TOKEN: 'portal/auth_token',
  AUTH_USER: 'portal/user_info',
};
const { AUTH_TOKEN, AUTH_USER } = localStorageKeys;

// keys de permisos
const BACKOFFICE_ACCESS = 'backoffice:access';

export const storage =
  typeof window !== 'undefined' ? window?.localStorage : {};

class AuthService {
  login = (credentials) => this.getToken(credentials);

  getToken = (credentials) => {
    const formData = qs.stringify({
      username: credentials.username,
      password: credentials.password,
    });

    let path = apiRoute.authToken;

    const internalData = qs.stringify({
      username: credentials.username,
      password: 'HARDCODED',
      roles: credentials?.roles,
      id_user: credentials.id_user,
    });

    if (credentials?.authentication === AuthMode.INTERNAL) {
      const id_user = credentials?.id_user;
      const authentication = credentials?.authentication;
      path = `${apiRoute.authToken}?id_user=${id_user}&authentication=${authentication}`;
    }

    const data =
      credentials?.authentication === AuthMode.INTERNAL
        ? internalData
        : formData;

    return axiosInstanceWithInterceptors.post(path, data);
  };

  getAuthHeaderBlob() {
    const typeToken = this.getTypeToken();
    const accessToken = this.getAccessToken();
    if (typeToken && accessToken) {
      return {
        responseType: 'blob',
        headers: {
          Accept: 'application/json',
          Authorization: typeToken + ' ' + accessToken,
        },
      };
    }
    return null;
  }

  saveTokenInLocalStorage = (response) => {
    const data = response?.data;
    const accessToken = data?.access_token;
    if (!accessToken) return false;
    try {
      storage.setItem(AUTH_TOKEN, JSON.stringify(data));
      return true;
    } catch (e) {
      return false;
    }
  };

  getUserInfo() {
    try {
      const authUser = JSON.parse(storage.getItem(AUTH_USER));
      return authUser ? authUser : '';
    } catch (e) {
      return false;
    }
  }

  getRoles() {
    return axiosInstanceWithInterceptors.get(apiRoute.roles);
  }

  getUsername() {
    const { username } = this.getUserInfo();
    return username ? username : '';
  }

  getUserId() {
    const { id_user } = this.getUserInfo();
    return id_user ? id_user : '';
  }

  canAccessToBackoffice() {
    return this.hasPermission(BACKOFFICE_ACCESS);
  }

  hasPermission(permission) {
    try {
      const { permissions } = this.getUserInfo();
      return permissions ? permissions.includes(permission) : false;
    } catch (e) {
      return false;
    }
  }

  hasRole(role) {
    const { roles } = this.getUserInfo();
    return roles ? roles.includes(role) : false;
  }

  getCurrentUserRoles() {
    const { roles } = this.getUserInfo();
    return roles ? roles : [];
  }

  isAuthenticated = () => {
    const token = this.getAccessToken();
    return !!token;
  };

  saveAuthToken(authToken) {
    try {
      storage.setItem(AUTH_TOKEN, JSON.stringify(authToken));
    } catch (e) {
      return false;
    }
  }

  getAuthToken() {
    try {
      const authToken = JSON.parse(storage.getItem(AUTH_TOKEN));
      return authToken ? authToken : '';
    } catch (e) {
      return false;
    }
  }

  getAuthHeader() {
    const typeToken = this.getTypeToken();
    const accessToken = this.getAccessToken();
    if (typeToken && accessToken) {
      return {
        headers: {
          Accept: 'application/json',
          Authorization: typeToken + ' ' + accessToken,
        },
      };
    }
    return null;
  }

  getAuthorizationHeader() {
    return this.getAuthHeader().headers['Authorization'];
  }

  getAccessToken() {
    return this.getAuthToken() && this.getAuthToken().access_token
      ? this.getAuthToken().access_token
      : null;
  }
  getTypeToken() {
    return this.getAuthToken() && this.getAuthToken().token_type
      ? this.getAuthToken().token_type
      : null;
  }

  refreshToken() {
    const token = this.getAuthToken();
    if (token === null) {
      return Promise.reject('no hay token pa refrescar');
    }
    return axiosInstanceWithInterceptors.post(apiRoute.refreshAuthToken, token);
  }

  logout() {
    try {
      storage.removeItem(AUTH_TOKEN);
      storage.removeItem(AUTH_USER);
    } catch (e) {
      return false;
    }
  }

  forceLogout(router) {
    this.logout();
    router.replace(routeLayout.login);
  }

  getUserData() {
    const userInfo = this.getUserInfo();
    let userData = userInfo === '' ? {} : userInfo;
    userData.isLoggedIn = this.isAuthenticated();
    return userData;
  }

  isLoggedUser = (idUser) => {
    return idUser === String(this.getUserId());
  };

  getAuthHeaderMultipart() {
    const typeToken = this.getTypeToken();
    const accessToken = this.getAccessToken();
    if (typeToken && accessToken) {
      return {
        headers: {
          Accept: 'application/json',
          Authorization: typeToken + ' ' + accessToken,
          'content-type': 'multipart/form-data',
        },
      };
    }
    return null;
  }

  getAuthHeaderMultipartBlob() {
    const typeToken = this.getTypeToken();
    const accessToken = this.getAccessToken();
    if (typeToken && accessToken) {
      return {
        headers: {
          Accept: 'application/json',
          Authorization: typeToken + ' ' + accessToken,
        },
        responseType: 'blob',
      };
    }
    return null;
  }

  statusControl() {
    return axiosInstanceWithInterceptors.get(apiRoute.statusControl);
  }
}

export default new AuthService();
