import { createContext, useState } from 'react';

const HeaderContext = createContext({});

export function HeaderProvider({ children }) {
  const [headerPreview, setHeaderPreview] = useState(false);

  return (
    <HeaderContext.Provider value={{ headerPreview, setHeaderPreview }}>
      {children}
    </HeaderContext.Provider>
  );
}

export default HeaderContext;
