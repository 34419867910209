import AuthService, { localStorageKeys } from '@services/AuthService';
import { createContext, useEffect, useState } from 'react';
import { useSnackBars } from './Snackbar/SnackBarContext';
import { COMMON_ERRORS, ReactQueryKeys } from '@utils/Constants';
import { useQuery } from 'react-query';
import UserService from '@services/UserService';

const CurrentUserContext = createContext({});

export function CurrentUserProvider({ children }) {
  const [userData, setUserData] = useState({});
  const { showErrorSnackbar } = useSnackBars();

  // useEffect para obtener los datos desde localStorage
  useEffect(() => {
    // Verifica si estamos en el cliente (navegador)
    if (typeof window !== 'undefined') {
      const storedUser = localStorage.getItem(localStorageKeys.AUTH_USER);
      if (storedUser !== 'undefined') {
        setUserData(JSON.parse(storedUser));
      }
    }
  }, []);

  const onGetCurrentUserSuccess = (data) => {
    setUserData(data?.data);
    UserService.saveCurrentUserInLocalStorage(data);
  };

  const onGetCurrentUserError = (error) => {
    const errorText = COMMON_ERRORS[error?.response_message];
    showErrorSnackbar(errorText);
    console.error(errorText);
  };

  const { isLoading, error } = useQuery(
    [ReactQueryKeys.CURRENT_USER],
    UserService.getCurrentUser,
    {
      enabled: AuthService.isAuthenticated(),
      initialData: userData,
      onSuccess: onGetCurrentUserSuccess,
      onError: onGetCurrentUserError,
    }
  );

  return (
    <CurrentUserContext.Provider
      value={{ userData, setUserData, isLoading, error }}
    >
      {children}
    </CurrentUserContext.Provider>
  );
}

export default CurrentUserContext;
