import AuthService, { localStorageKeys } from 'src/services/AuthService';
import axiosInstanceWithInterceptors from 'src/services/InterceptorsForAxiosRequests';
import { apiRoute } from 'src/paths';
import { getUserFilters } from 'src/utils/Functions';

const AUTH_USER = localStorageKeys?.AUTH_USER;

export const storage =
  typeof window !== 'undefined' ? window?.localStorage : {};

class UserService {
  //para el login
  getCurrentUser() {
    return axiosInstanceWithInterceptors.get(
      apiRoute.currentUser,
      AuthService.getAuthHeader()
    );
  }
  getBirthday({ rangeDays, boss }) {
    return axiosInstanceWithInterceptors.get(
      apiRoute.users + '/birthday/?boss=' + boss + '&range=' + rangeDays,
      AuthService.getAuthHeader()
    );
  }

  getCurrentUserAndSaveInLocalStorage = async () => {
    return await UserService.getCurrentUser().then((userInfoResponse) => {
      const { data } = userInfoResponse;
      const { permissions } = data;
      if (!permissions) {
        return false;
      }
      this.saveUserInfo(data);
      return userInfoResponse;
    });
  };

  saveCurrentUserInLocalStorage = (userInfoResponse) => {
    const { data } = userInfoResponse;
    try {
      storage.setItem(AUTH_USER, JSON.stringify(data));
      return userInfoResponse;
    } catch (e) {
      return false;
    }
  };

  getUsers(params) {
    let filters = getUserFilters(params);
    return axiosInstanceWithInterceptors.get(
      apiRoute.users + '?' + filters,
      AuthService.getAuthHeader()
    );
  }

  addNewUser(userData) {
    return axiosInstanceWithInterceptors.post(
      apiRoute.users,
      userData,
      AuthService.getAuthHeader()
    );
  }

  editUser(user) {
    return axiosInstanceWithInterceptors.patch(
      apiRoute.users + '/' + user.id,
      user.data,
      AuthService.getAuthHeader()
    );
  }
  editUserPermissions(data) {
    return axiosInstanceWithInterceptors.patch(
      apiRoute.editUserPermissions(data),
      {},
      AuthService.getAuthHeader()
    );
  }

  autocompleteUserBoss(params) {
    return axiosInstanceWithInterceptors.get(
      apiRoute.usersAutocompleteBoss(params),
      AuthService.getAuthHeader()
    );
  }

  autocompleteUsersToShareLicenses(q) {
    return axiosInstanceWithInterceptors.get(
      apiRoute.autocompleteUsersToShareLicenses(encodeURIComponent(q)),
      AuthService.getAuthHeader()
    );
  }

  shareLicenses({ permiso, usersSelected }) {
    return axiosInstanceWithInterceptors.patch(
      apiRoute.shareLicenses(permiso),
      usersSelected,
      AuthService.getAuthHeader()
    );
  }
  deleteShareUser(id) {
    const token = AuthService.getAuthHeader();
    return axiosInstanceWithInterceptors.delete(
      apiRoute.deleteshareUser(id),
      token
    );
  }

  getTuArsat() {
    return axiosInstanceWithInterceptors.get(
      apiRoute.tuArsat,
      AuthService.getAuthHeader()
    );
  }

  editTuArsatContact(body) {
    return axiosInstanceWithInterceptors.patch(
      apiRoute.tuArsatContact,
      body,
      AuthService.getAuthHeader()
    );
  }

  uploadProfileImage(body) {
    const token = AuthService.getAuthHeader();
    return axiosInstanceWithInterceptors.post(
      apiRoute.uploadProfileImage(),
      body,
      token
    );
  }

  getWorkPlaceFirstOption() {
    return axiosInstanceWithInterceptors.get(
      apiRoute.workPlaceFirstOption(),
      AuthService.getAuthHeader()
    );
  }
  getWorkPlaceSecondOption(id) {
    return axiosInstanceWithInterceptors.get(
      apiRoute.workPlaceSecondOption(id),
      AuthService.getAuthHeader()
    );
  }
  getWorkPlaceThirdOption(id) {
    return axiosInstanceWithInterceptors.get(
      apiRoute.workPlaceThirdOption(id),
      AuthService.getAuthHeader()
    );
  }
  updateWorkPlace(body) {
    const token = AuthService.getAuthHeader();
    return axiosInstanceWithInterceptors.patch(
      apiRoute.workPlaceOption,
      body,
      token
    );
  }

  getDirectory(params) {
    let filters = getUserFilters(params);
    return axiosInstanceWithInterceptors.get(
      apiRoute.directory + '?' + filters,
      AuthService.getAuthHeader()
    );
  }

  getManagments() {
    return axiosInstanceWithInterceptors.get(
      apiRoute.getManagments(),
      AuthService.getAuthHeader()
    );
  }

  getDirectoryIndex(params) {
    let filters = getUserFilters(params);
    return axiosInstanceWithInterceptors.get(
      apiRoute.getDirectoryIndex(filters),
      AuthService.getAuthHeader()
    );
  }

  addToFavorites(id) {
    return axiosInstanceWithInterceptors.patch(
      apiRoute.addToFavorites(id),
      {},
      AuthService.getAuthHeader()
    );
  }
  removeToFavorites(id) {
    return axiosInstanceWithInterceptors.delete(
      apiRoute.removeToFavorites(id),
      AuthService.getAuthHeader()
    );
  }
}

export default new UserService();
