import ErrorBoundary from '@components/ErrorBoundary';
import { CurrentUserProvider } from '@contexts/CurrentUserContext';
import { Features } from '@contexts/Features/Features';
import { FeaturesProvider } from '@contexts/Features/FeaturesContext';
import { HeaderProvider } from '@contexts/HeaderContext';
import { SnackBarProvider } from '@contexts/Snackbar/SnackBarContext';
import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider } from '@mui/material/styles';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { IconContext } from '@phosphor-icons/react';
import AuthService from '@services/AuthService';
import { queryClient } from '@services/queryClient';
import dayjs from 'dayjs';
import { es } from 'dayjs/locale/es'; // Sin esto rompe los datepicker o corre los días de la semana del calendario
import Head from 'next/head';
import { useRouter } from 'next/router';
import { useEffect } from 'react';
import { QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { routeLayout, routesWithoutAuth } from 'src/paths';
import theme from 'src/theme';
import '../styles.css';
dayjs.locale('es');
console.log(es);

export default function MyApp(props) {
  const { Component, pageProps } = props;
  const getLayout = Component.getLayout || ((page) => page);
  const router = useRouter();
  const isLoginRoute = Object.values(routesWithoutAuth).includes(
    router.pathname
  );
  const isAuthenticated = AuthService.isAuthenticated();

  useEffect(() => {
    // checks if the user is authenticated
    if (!isLoginRoute && !isAuthenticated && router?.isReady) {
      const redirectTo = router.asPath;
      const url = routeLayout.login + '?redirectTo=' + redirectTo;
      window.location.href = url;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [router?.isReady]);

  return (
    <>
      <Head>
        <meta name="viewport" content="initial-scale=1, width=device-width" />
        <title>{process.env.NEXT_PUBLIC_APP_NAME}</title>
        <link
          rel="apple-touch-icon"
          sizes="180x180"
          href="/apple-touch-icon.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="32x32"
          href="/favicon-32x32.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="16x16"
          href="/favicon-16x16.png"
        />
        <link rel="manifest" href="/site.webmanifest" />
        <link rel="mask-icon" href="/safari-pinned-tab.svg" color="#5bbad5" />
        <meta name="msapplication-TileColor" content="#da532c" />
        <meta name="theme-color" content="#ffffff" />
        <meta name="robots" content="noindex, nofollow" />
      </Head>
      <h1 style={{ display: 'none' }}>{process.env.NEXT_PUBLIC_APP_NAME}</h1>
      <div id="top-page"></div>

      <ErrorBoundary>
        <QueryClientProvider client={queryClient}>
          <SnackBarProvider>
            <CurrentUserProvider>
              <FeaturesProvider value={Features}>
                <HeaderProvider>
                  <LocalizationProvider
                    adapterLocale="es"
                    dateAdapter={AdapterDayjs}
                  >
                    <ReactQueryDevtools
                      initialIsOpen={false}
                      position="bottom-left"
                    />
                    <IconContext.Provider
                      value={{
                        color: theme.palette.primary.main,
                        size: 24,
                        alt: '.',
                      }}
                    >
                      <ThemeProvider theme={theme}>
                        <CssBaseline />
                        {getLayout(<Component {...pageProps} />)}
                      </ThemeProvider>
                    </IconContext.Provider>
                  </LocalizationProvider>
                </HeaderProvider>
              </FeaturesProvider>
            </CurrentUserProvider>
          </SnackBarProvider>
        </QueryClientProvider>
      </ErrorBoundary>
    </>
  );
}
