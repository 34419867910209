import 'moment/locale/es';
import moment from 'moment';
import { MANUAL_LICENSE, TREE_STATUS_AUTHORIZATION } from '@utils/Constants';
import { formatHistoryDate } from '@utils/date';
import { isManualLicense } from '@utils/Functions';

export const toUpperCaseMonth = (fecha) => {
  let partes = fecha.split(' '); // Separa la cadena en tres partes
  partes[1] = partes[1]?.charAt(0)?.toUpperCase() + partes[1]?.slice(1); // Capitaliza el mes
  fecha = partes.join(' '); // Vuelve a unir las partes
  return fecha;
};

export const getdateFormFormat = (row) => {
  return toUpperCaseMonth(
    formatHistoryDate(row.from_date).replace('.', '') ?? ''
  );
};

export const getdateToFormat = (row) => {
  return toUpperCaseMonth(
    formatHistoryDate(row.to_date).replace('.', '') ?? ''
  );
};

export const getdateCreated = (row) => {
  return toUpperCaseMonth(
    formatHistoryDate(row.date_created).replace('.', '') ?? ''
  );
};

export const getDate = (row) => {
  const dateForm = getdateFormFormat(row);
  const dateTo = getdateToFormat(row);

  return `${dateForm} - ${row.to_date ? dateTo : ''} `;
};

export const getBirdthday = (row) => {
  const { birthday } = row;
  const dateForm = moment(birthday).format('D MMM YYYY').replace('.', '');

  return `${toUpperCaseMonth(dateForm)} -  `;
};

export const getNumberOfDays = (row) => {
  const number = isManualLicense(row)
    ? row?.days?.[MANUAL_LICENSE]?.requested
    : row?.days?.[row?.type]?.requested;
  return number;
};

export const getName = (row) => {
  const { first_name, last_name } = row;
  return `${last_name}, ${first_name}`;
};

export const isSelectedFilter = (value, urlKeys) => {
  if (urlKeys?.find((item) => item === value)) return true;
  return false;
};

export const removeFilter = (value, urlKeys) => {
  const typesKeys = urlKeys?.filter((item) => item !== value);
  return typesKeys;
};

export const addFilterToUrl = (value, urlKeys) => {
  return urlKeys ? [...urlKeys, value] : [value];
};

export const getLicenseTypeNames = (licenseTypes, arrayNames) => {
  const licenseTypeNames = licenseTypes.map((type) => arrayNames[type]);
  return licenseTypeNames;
};

export const getOptionsSelectedNames = (licenseTypes, arrayNames) => {
  let licenseTypesAux = licenseTypes;
  if (licenseTypesAux?.length > 0) {
    licenseTypesAux = licenseTypesAux?.filter((type) => arrayNames[type]);
  }

  const licenseTypeNames = licenseTypesAux?.map((type) => arrayNames[type]);

  return licenseTypeNames;
};

export const getKeysExcept = (object, excludedKey) => {
  return Object.keys(object).filter((key) => key !== excludedKey);
};

export const isAutomaticApproval = (row) => {
  const { autorizacion } = row;

  return autorizacion === TREE_STATUS_AUTHORIZATION.automaticApproval;
};
