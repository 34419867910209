import { LICENSES_ALL_TYPES, LICENSES_STATUS } from '@utils/Constants';
import { apiRoute } from '../paths';
import AuthService from './AuthService';
import axiosInstanceWithInterceptors from './InterceptorsForAxiosRequests';
import { isAllSelected } from '@utils/Functions';

export class License {
  newLicense(data) {
    const token = AuthService.getAuthHeader();
    return axiosInstanceWithInterceptors.post(apiRoute.licenses, data, token);
  }
  newManualLicense(data) {
    const token = AuthService.getAuthHeader();
    return axiosInstanceWithInterceptors.post(
      apiRoute.manualLicense(data),
      data,
      token
    );
  }
  editManualLicense(data) {
    const token = AuthService.getAuthHeader();
    return axiosInstanceWithInterceptors.put(
      apiRoute.editManualLicense(data),
      data,
      token
    );
  }

  uploadDocuments({ formData, id_license, medical_confidentiality = false }) {
    const token_multi_part = AuthService.getAuthHeaderMultipart();
    let queryParams = id_license ? '?id_license=' + id_license : '?';
    queryParams += medical_confidentiality
      ? '&medical_confidentiality=' + medical_confidentiality
      : '';
    return axiosInstanceWithInterceptors.post(
      apiRoute.documentUpload() + queryParams,
      formData,
      token_multi_part
    );
  }

  uploadCertificate({ formData, id_license, medical_confidentiality = false }) {
    const token_multi_part = AuthService.getAuthHeaderMultipart();
    let queryParams = id_license ? '?id_license=' + id_license : '?';
    queryParams += medical_confidentiality
      ? '&medical_confidentiality=' + medical_confidentiality
      : '';
    return axiosInstanceWithInterceptors.post(
      apiRoute.uploadCertificate + queryParams,
      formData,
      token_multi_part
    );
  }

  newTagEmployes(data) {
    const token = AuthService.getAuthHeader();
    return axiosInstanceWithInterceptors.post(
      apiRoute.newTagEmployes,
      data,
      token
    );
  }

  deleteTagEmployes(tag) {
    const token = AuthService.getAuthHeader();
    return axiosInstanceWithInterceptors.delete(
      apiRoute.deleteTagEmployes(tag),
      token
    );
  }
  getBossTagsEmployes(params) {
    let filters = this.getTableFilters(params);
    return axiosInstanceWithInterceptors.get(
      apiRoute.getBossTagsEmployes(filters),
      AuthService.getAuthHeader()
    );
  }
  getBossWarningsLicenses(params) {
    let filters = this.getTableFilters(params);
    return axiosInstanceWithInterceptors.get(
      apiRoute.getBossWarnings(filters),
      AuthService.getAuthHeader()
    );
  }
  editBossTagsEmployes(data) {
    const token = AuthService.getAuthHeader();
    return axiosInstanceWithInterceptors.patch(
      apiRoute.editBossTagsEmployes(data?.idUser),
      data,
      token
    );
  }

  editTagEmployes(data) {
    const token = AuthService.getAuthHeader();
    return axiosInstanceWithInterceptors.patch(
      apiRoute.editTagEmployes(data?.oldTag),
      data,
      token
    );
  }

  uploadMedicalExtension({ formData, id_license }) {
    const token_multi_part = AuthService.getAuthHeaderMultipart();
    return axiosInstanceWithInterceptors.patch(
      apiRoute.uploadMedicalExtension(id_license),
      formData,
      token_multi_part
    );
  }

  uploadAndSendMessage({ formData, id_license }) {
    const token_multi_part = AuthService.getAuthHeaderMultipart();
    return axiosInstanceWithInterceptors.post(
      apiRoute.uploadAndSendMessage(id_license),
      formData,
      token_multi_part
    );
  }

  cancelLicense(id) {
    const token = AuthService.getAuthHeader();
    return axiosInstanceWithInterceptors.patch(
      `${apiRoute.cancelLicenseCu}${id}/cancel/`,
      {},
      token
    );
  }

  hasErrors({ licenseType, from_date, to_date, modality }) {
    const token = AuthService.getAuthHeader();
    let filters = '?license_type=' + licenseType;
    filters += from_date ? '&from_date=' + encodeURIComponent(from_date) : '';
    filters += to_date ? '&to_date=' + encodeURIComponent(to_date) : '';
    filters += modality ? '&modality=' + modality : '';
    return axiosInstanceWithInterceptors.get(apiRoute.warning + filters, token);
  }

  getAllLicenses(params) {
    let filters = this.getTableFilters(params, true);
    return axiosInstanceWithInterceptors.get(
      apiRoute.listBOLicenses(filters),
      AuthService.getAuthHeader()
    );
  }

  getLicensesBoss(params, sort) {
    let filters = this.getTableFilters(params, sort);
    return axiosInstanceWithInterceptors.get(
      apiRoute.listBOLicensesBoss(filters),
      AuthService.getAuthHeader()
    );
  }

  getGanttLicensesBoss(params) {
    let filters = this.getTableFilters(params, true);
    return axiosInstanceWithInterceptors.get(
      apiRoute.listGanttLicensesBoss(filters),
      AuthService.getAuthHeader()
    );
  }

  getTutorials(type) {
    return axiosInstanceWithInterceptors.get(
      apiRoute.getTutorials(type),
      AuthService.getAuthHeader()
    );
  }

  getLicensesActives(params, sort) {
    const token = AuthService.getAuthHeader();
    let filters = this.getTableFilters(params, sort);

    return axiosInstanceWithInterceptors.get(
      `${apiRoute.listLicensesCu}?${filters}`,
      token
    );
  }

  getLicenseById(id) {
    const token = AuthService.getAuthHeader();
    const path = `${apiRoute.licenses}${id}`;
    return axiosInstanceWithInterceptors.get(path, token);
  }

  approvalHandler({ event, id, description }) {
    const token = AuthService.getAuthHeader();
    return axiosInstanceWithInterceptors.patch(
      `${apiRoute.licenses}${id}/${event}`,
      { description },
      token
    );
  }

  getDocument(id) {
    const token = AuthService.getAuthHeaderBlob();
    return axiosInstanceWithInterceptors.get(
      `${apiRoute.licenses}documents/certificate/${id}`,
      token
    );
  }
  getHolidays() {
    const token = AuthService.getAuthHeader();
    return axiosInstanceWithInterceptors.get(
      `${apiRoute.getHolidays()}?sort_order=1`,
      token
    );
  }

  getTableFilters = (params, sort = false) => {
    const {
      q,
      types,
      page_size,
      page_num,
      status_process,
      categoria_medica,
      sort_field,
      sort_order,
      vista,
      active,
      from_date,
      to_date,
      tags,
      idLicense,
      idUserCompartido,
      idRelatedUser,
    } = params;

    let filters = '';

    filters += q ? 'q=' + encodeURIComponent(q.trim()) : '';
    filters += vista ? '&vista=' + vista : '';
    if (
      types &&
      types.length !== 0 &&
      !isAllSelected(types, LICENSES_ALL_TYPES)
    ) {
      types.map((item) => (filters += '&type=' + item));
    }
    if (tags && tags.length !== 0) {
      tags.map((item) => (filters += '&etiquetas=' + item));
    }
    filters += page_size ? '&page_size=' + page_size : '';
    filters += page_num ? '&page_num=' + page_num : '';
    filters += active ? '&active=' + active : '';

    filters += from_date ? '&from_date=' + encodeURIComponent(from_date) : '';
    filters += to_date ? '&to_date=' + encodeURIComponent(to_date) : '';
    filters += idLicense ? '&id_license_excluded=' + idLicense : '';
    filters += idUserCompartido
      ? '&id_user_compartido=' + idUserCompartido
      : '';
    filters += idRelatedUser ? '&id_related_user=' + idRelatedUser : '';

    if (
      status_process &&
      status_process.length !== 0 &&
      !isAllSelected(status_process, LICENSES_STATUS)
    ) {
      status_process.map((item) => (filters += '&status_process=' + item));
    }

    filters +=
      categoria_medica && categoria_medica !== 'all'
        ? '&categoria_medica=' + categoria_medica
        : '';

    if (sort) {
      filters += sort_field
        ? '&sort_field=' + sort_field
        : '&sort_field=date_created';
      filters += sort_order ? '&sort_order=' + sort_order : '&sort_order=-1';
    }

    return filters;
  };

  getOtherLicensesPageLink() {
    const token = AuthService.getAuthHeader();
    return axiosInstanceWithInterceptors.get(
      `${apiRoute.otherLicenses}`,
      token
    );
  }
  deleteManualLicense(params) {
    const token = AuthService.getAuthHeader();
    return axiosInstanceWithInterceptors.delete(
      apiRoute.deleteManualLicense(params),
      token
    );
  }
}

export const LicenseService = new License();
