const TuEquipoIcon = ({ color }) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12 16.875C14.0711 16.875 15.75 15.1961 15.75 13.125C15.75 11.0539 14.0711 9.375 12 9.375C9.92893 9.375 8.25 11.0539 8.25 13.125C8.25 15.1961 9.92893 16.875 12 16.875Z"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M18.375 10.875C19.2485 10.8735 20.1103 11.0762 20.8916 11.4669C21.6729 11.8575 22.3521 12.4253 22.875 13.125"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M1.125 13.125C1.64794 12.4253 2.32714 11.8575 3.10843 11.4669C3.88972 11.0762 4.75149 10.8735 5.625 10.875"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.59985 20.25C7.09375 19.2386 7.8618 18.3861 8.81652 17.7899C9.77124 17.1936 10.8742 16.8774 11.9999 16.8774C13.1255 16.8774 14.2285 17.1936 15.1832 17.7899C16.1379 18.3861 16.906 19.2386 17.3999 20.25"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.62512 10.875C5.05572 10.8756 4.49791 10.7141 4.01688 10.4094C3.53585 10.1048 3.15145 9.6695 2.9086 9.15449C2.66576 8.63947 2.57449 8.06598 2.64547 7.50102C2.71645 6.93607 2.94675 6.40298 3.30945 5.96404C3.67215 5.52511 4.15227 5.19845 4.69372 5.02225C5.23517 4.84605 5.81558 4.82758 6.36714 4.96899C6.9187 5.11041 7.41862 5.40587 7.8085 5.82085C8.19838 6.23583 8.4621 6.7532 8.56887 7.3125"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.4314 7.3125C15.5382 6.7532 15.8019 6.23583 16.1918 5.82085C16.5816 5.40587 17.0816 5.11041 17.6331 4.96899C18.1847 4.82758 18.7651 4.84605 19.3065 5.02225C19.848 5.19845 20.3281 5.52511 20.6908 5.96404C21.0535 6.40298 21.2838 6.93607 21.3548 7.50102C21.4258 8.06598 21.3345 8.63947 21.0917 9.15449C20.8488 9.6695 20.4644 10.1048 19.9834 10.4094C19.5023 10.7141 18.9445 10.8756 18.3751 10.875"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default TuEquipoIcon;
