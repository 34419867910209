import React from 'react';
import { Button, Grid, Link, Typography } from '@mui/material';
import * as Sentry from '@sentry/browser'; //TODO: INTEGRAR SENTRY

const isChunkLoadError = (error) => {
  return error.name === 'ChunkLoadError';
};

const shouldShowSentryFeedback = (stateError) => {
  return !isChunkLoadError(stateError);
};

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false, error: '', eventId: '', errorInfo: '' };
  }
  static getDerivedStateFromError() {
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    console.error({ error, errorInfo });
    Sentry.withScope((scope) => {
      scope.setExtras(errorInfo);
      const eventId = Sentry.captureException(error);
      this.setState({ eventId, errorInfo });
    });
  }

  render() {
    if (this.state.hasError) {
      return (
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
          style={{
            height: 'calc(100% - 100px)',
            padding: '5% 32px 0',
          }}
        >
          <Grid sx={{ display: 'flex', maxWidth: 594 }}>
            <Grid container spacing={2}>
              <Grid
                container
                alignItems="center"
                style={{
                  marginBottom: 32,
                }}
              >
                <Grid item xs={12} sm={7}>
                  <h3>
                    {isChunkLoadError(this.state.error) ? (
                      <Typography
                        sx={{
                          fontSize: 20,
                          fontWeight: 700,
                        }}
                      >
                        Hay una nueva versión y se debe actualizar
                      </Typography>
                    ) : (
                      <Typography
                        sx={{
                          fontSize: 20,
                          fontWeight: 700,
                        }}
                      >
                        ¡Ocurrió un error al cargar la página!
                      </Typography>
                    )}
                  </h3>
                </Grid>
                <Grid item container xs={12} sm={5}>
                  <Button
                    sx={{
                      color: '#fff',
                      backgroundColor: '#000',
                      border: '1px solid #FFF',
                      borderRadius: 14,
                      pl: 2,
                      pr: 2,
                      '&:hover': {
                        color: '#000',
                        backgroundColor: '#fff',
                        border: '1px solid #000',
                      },
                    }}
                    onClick={() => window.location.reload()}
                  >
                    <Typography
                      sx={{
                        fontWeight: 500,
                        fontSize: 13,
                      }}
                    >
                      Volver a cargar la página
                    </Typography>
                  </Button>
                </Grid>
              </Grid>
              {shouldShowSentryFeedback(this.state.error) && (
                <>
                  <Grid container item spacing={2}>
                    <Typography sx={{ pb: 2 }}>
                      ¿Nos contarías qué ocurrió? Tu experiencia puede ayudarnos
                      a encontrar la solución
                    </Typography>
                  </Grid>
                  <Grid container item spacing={2}>
                    <Link
                      style={{
                        color: 'blue',
                        textDecoration: 'underline',
                        cursor: 'pointer',
                      }}
                      onClick={() => {
                        Sentry.showReportDialog({
                          eventId: this.state.eventId,
                        });
                      }}
                      underline="hover"
                    >
                      <Typography>Enviar comentarios</Typography>
                    </Link>
                    <span>&nbsp;*</span>
                  </Grid>
                  <Typography sx={{ fontSize: 12, pb: 2, pt: 2 }}>
                    *Tené en cuenta que para que se abra la ventana emergente,
                    será necesario que desactives tu bloqueador de anuncio (en
                    el caso que tengas uno)
                  </Typography>
                  <div>
                    <details>
                      <summary
                        style={{
                          cursor: 'pointer',
                        }}
                      >
                        <Typography sx={{ pl: 2, mt: -2.625 }}>
                          Ver más detalles del error
                        </Typography>
                      </summary>
                      {this.state.errorInfo && (
                        <Typography sx={{ overflowWrap: 'anywhere' }}>
                          {this.state.errorInfo.componentStack.toString()}
                        </Typography>
                      )}
                    </details>
                  </div>
                </>
              )}
            </Grid>
          </Grid>
        </Grid>
      );
    }

    // Return children components in case of no error

    return this.props.children;
  }
}

export default ErrorBoundary;
