export const routesWithoutAuth = {
  login: '/',
  get debug() {
    return `${this.login}?debug=${true}`;
  },
  statusControl: '/status-control',
  sentryExamplePage: '/sentry-example-page',
};

export const routeLayout = {
  ...routesWithoutAuth,
  home: '/inicio',
  logout: '/cerrar-sesion',
  notFoundPage: '/404',
  check: '/fichaje',
  licenses: '/tu-arsat/licencias',
  team: '/equipo',
  institutional: '/institucional',
  notes: '/notas',
  paycheck: '/recibos',
  search: '/resultados-busqueda',

  get sharedLicensesGantt() {
    return (id_user) => `/compartidos/${id_user}/licencias/cronograma/`;
  },
  get sharedLicensesList() {
    return (id_user) => `/compartidos/${id_user}/licencias/listado/`;
  },
};

const paychecksRoutes = {
  get getTagsPaycheck() {
    return () => `${routeLayout.paycheck}/tags/autocomplete/`;
  },
};

export const apiRoute = {
  authToken: '/auth/token',
  statusControl: '/auth/status/all/',
  forgotAuthPassword: '/auth/forgot_password',
  refreshAuthToken: '/auth/refresh_token',
  roles: '/auth/roles/',
  currentUser: '/users/current/',
  users: '/users',
  licenses: '/licenses/',
  listLicensesCu: '/licenses/current-user/',
  cancelLicenseCu: '/licenses/current-user/',
  warning: '/licenses/warning/',
  contenidos: '/contenidos',
  uploadCertificate: '/licenses/documents/certificate/',
  getInstitutionals: 'contenidos/institucionales/',
  otherLicenses: 'licenses/link-licencias/',
  tuArsat: '/users/tu-arsat/',
  tuArsatContact: '/users/tu-arsat/contacto/',
  workPlaceOption: '/users/tu-arsat/lugar-trabajo/',
  directory: '/users/directorio/',
  busquedas: '/busquedas/',
  recibos: '/recibos/',
  recibosPasswordsForgot: '/recibos/passwords/forgot/',
  recibosPasswordsChange: '/recibos/passwords/user/',
  recibosPasswordsDefaultChange: '/recibos/passwords/user/new/',
  recibosCheckIfUserHasPasswordsDefault: '/recibos/passwords/user/default/',
  checkStatus: '/presentismo/jornada/',
  checkIn: '/presentismo/jornada/iniciar/',
  checkOut: '/presentismo/jornada/finalizar/',

  get manualLicense() {
    return (params) =>
      `${this.licenses}manual/${
        params?.id_user_compartido
          ? '?id_user_compartido=' + params.id_user_compartido
          : ''
      }`;
  },

  get deleteManualLicense() {
    return (params) =>
      `${this.licenses}manual/${params.id_license}${
        params.id_user_compartido
          ? '?id_user_compartido=' + params.id_user_compartido
          : ''
      }`;
  },

  get editManualLicense() {
    return (params) =>
      `${this.licenses}manual/${params.id}${
        params?.id_user_compartido
          ? '?id_user_compartido=' + params.id_user_compartido
          : ''
      }`;
  },

  get usersAutocompleteBoss() {
    return (params) =>
      `${this.users}/autocomplete/boss?q=${encodeURIComponent(params.search)}${
        params?.id_related_user
          ? '&id_related_user=' + params.id_related_user
          : ''
      }`;
  },

  get editUserPermissions() {
    return ({ id, permission }) =>
      `${this.users}/compartidos/${id}/permiso/?permiso=${permission}`;
  },

  get listBOLicenses() {
    return (filters) => `${this.licenses}current-user/?${filters}`;
  },
  get listBOLicensesBoss() {
    return (filters) => `${this.licenses}boss/?${filters}`;
  },
  get listGanttLicensesBoss() {
    return (filters) => `${this.licenses}gantt/?${filters}`;
  },
  get getBossTagsEmployes() {
    // GET Method
    return (filters) => `${this.licenses}etiquetas/autocomplete/?${filters}`;
  },
  get newTagEmployes() {
    return `${this.licenses}etiquetas/`;
  },
  get deleteTagEmployes() {
    return (tag) => `${this.licenses}etiquetas/?etiqueta=${tag}`;
  },
  get editTagEmployes() {
    return (tag) => `${this.licenses}etiquetas/?etiqueta=${tag}`;
  },
  get editBossTagsEmployes() {
    return (idUser) => `${this.licenses}etiquetas/${idUser}/asignar/`;
  },
  get getBossWarnings() {
    return (filters) => `${this.licenses}boss/warnings/?${filters}`;
  },
  get getHolidays() {
    return () => `${this.licenses}feriados/`;
  },
  get documentUpload() {
    // POST Method
    return () => `${this.licenses}documents/upload/`;
  },
  get uploadMedicalExtension() {
    return (id_license) => `${this.licenses}${id_license}/extend/`;
  },
  get uploadAndSendMessage() {
    return (id_license) =>
      `${this.licenses}documents/mensaje/?id_license=${id_license}`;
  },
  get getTutorials() {
    // POST Method
    return (type) => `${this.licenses}tutoriales/?tipo_licencia=${type}`;
  },
  get newNotes() {
    return `${this.contenidos}/notas/`;
  },
  get getHomeDirectAccess() {
    return `${this.contenidos}/home/accesos_directos/`;
  },
  get addTopics() {
    return `${this.contenidos}/temas/`;
  },
  get getNotes() {
    return (filters) => `${this.contenidos}/notas/?${filters}`;
  },
  get getNotesById() {
    return (id) => `${this.contenidos}/notas/${id}`;
  },
  get getTopics() {
    return (filters) => `${this.contenidos}/temas/?${filters}`;
  },
  get getContenidoById() {
    return ({ id, tipo }) => `${this.contenidos}/contenido/${id}?tipo=${tipo}`;
  },
  get getContenidosByType() {
    return ({ tipo, filters }) =>
      `${this.contenidos}/contenido/?tipo=${tipo}&prioridad=${filters.prioridad}&tipo_comunicado=${filters.tipo_comunicado}`;
  },
  get getHome() {
    return (filters) => `${this.contenidos}/home/?${filters}`;
  },

  get getContenidoRepository() {
    return (filters) => `${this.contenidos}/contenido/?${filters}`;
  },

  get autocompleteUsersToShareLicenses() {
    return (q) => `${this.users}/autocomplete/compartir?q=${q}`;
  },
  get shareLicenses() {
    return (permiso) => `${this.users}/compartidos/?permiso=${permiso}`;
  },
  get deleteshareUser() {
    return (id) => `${this.users}/compartidos/${id}/`;
  },
  get uploadProfileImage() {
    return () => `${this.users}/archivos/foto/`;
  },
  get workPlaceFirstOption() {
    return () => `${this.workPlaceOption}opcion1/`;
  },
  get workPlaceSecondOption() {
    return (id) => `${this.workPlaceOption}opcion2/?id_lugar_trabajo=${id}`;
  },
  get workPlaceThirdOption() {
    return (id) => `${this.workPlaceOption}opcion3/?id_detalle=${id}`;
  },
  get getAllDirectory() {
    return (filters) => `${this.directory}?${filters}`;
  },
  get getManagments() {
    return () => `${this.directory}gerencias/`;
  },
  get getDirectoryIndex() {
    return (filters) => `${this.directory}indice/?${filters}`;
  },
  get addToFavorites() {
    return (id) => `${this.directory}favorito/?id_user=${id}`;
  },
  get removeToFavorites() {
    return (id) => `${this.directory}favorito/?id_user=${id}`;
  },
  get getUnvisualizedPaychecks() {
    return () => `${this.recibos}current-user/count-sin-visualizar/`;
  },

  get getRecibosCU() {
    return (filters) => `${this.recibos}current-user/?${filters}`;
  },

  get recibosZipDownload() {
    return (filters) => `${this.recibos}zip-download/?${filters}`;
  },
  ...paychecksRoutes,
};

export const LicensesRoutes = {
  landing: '/tu-arsat',
  licenseById: '/tu-arsat/licencias/detalle/',
  licenseByIdMyTeam: '/equipo/licencias/detalle/',
  listLicenses: '/equipo/licencias/listado',
  schedulerLicenses: '/equipo/licencias/cronograma',
  vacation: '/tu-arsat/licencias/vacaciones',
  procedure: '/tu-arsat/licencias/tramites',
  exam: '/tu-arsat/licencias/examen',
  medical: '/tu-arsat/licencias/enfermedad',
  RequestGenerated: '/tu-arsat/licencias/solicitud-generada/',
  othersDefault: 'https://portal.arsat.com.ar/paginas/11-otras-licencias',
};
